<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">&nbsp;</span>
      </h3>
      <div class="card-toolbar">
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm mb-8"
          @click.prevent="exportExcelClicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('COMMON.EXPORT_XLSX')}}</a
        >
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0">
      <b-table
        responsive
        id="stickprov-table"
        :fields="headers"
        :items="members"
        head-variant="light"
        class="w-100"
      >
        <template #cell(do_manual)="row">
          <b-button
            v-if="row.item.do_manual && !row.item.done"
            class="mr-2"
            type="button"
            variant="primary"
            @click.prevent="confirm(row.item)"
            >{{ $t('SAMPLE.ANSWER_YES') }}</b-button
          >
          <b-button
            v-if="row.item.do_manual && !row.item.done"
            type="button"
            variant="danger"
            @click.prevent="reject(row.item)"
            >{{ $t('SAMPLE.ANSWER_NO') }}</b-button
          >

          <b-badge v-if="!row.item.do_manual" variant="light">{{ $t('COMMON.NO') }}</b-badge>
        </template>
        <template #cell(status)="row">
          <b-badge v-if="row.item.con" variant="success">{{ $t('SAMPLE.STATUSES.CON') }}</b-badge>
          <b-badge v-if="row.item.rej" variant="danger">{{ $t('SAMPLE.STATUSES.REJ') }}</b-badge>
          <b-badge v-if="!row.item.ans" variant="warning">{{ $t('SAMPLE.STATUSES.NO_ANS') }}</b-badge>
        </template>
      </b-table>
    </div>

  </div>
  <!--end::Advance Table Widget 9-->
</template>
<style lang="scss" scoped>
</style>
<script>

import axios from 'axios';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

export default {
  name: 'stickprov-members-table',
  props: ['members', 'currentCompanyId', 'stickprov'],
  emits: ['confirm', 'reject', 'selectMemberClicked', 'deleteMemberClicked'],
  mixins: [ toasts ],
  components: {

  },
  watch: {

  },
  methods: {
    exportExcelClicked() {
      axios
        .post(`/stickprov/xlsx/${this.stickprov.stickprov_id}`, {
          stickprov_id: this.stickprov.stickprov_id,
          company_id: this.currentCompanyId
        })
        .then(res => {
          if (res.status === 200) {
            downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SAMPLE.UNABLE_CREATE_EXCEL') );
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SAMPLE.UNABLE_CREATE_EXCEL'));
        });
    },
    confirm(row) {
      this.$emit('confirm', row.member_id);
      row.status = 'confirmed';
    },
    reject(row) {
      this.$emit('reject', row.member_id);
      row.status = 'rejected';
    },
    selectMemberClicked(member_id) {
      this.$emit('selectMemberClicked', member_id);
    },
    deleteMemberClicked(member_id) {
      this.$emit('deleteMemberClicked', member_id);
    }
  },
  data() {
    return {
      show_history: false,
      headers: [
        {
          key: 'name',
          label: this.$t('MEMBER.NAME'),
          formatter: (_, __, item) => {
            return `${item.member.firstname} ${item.member.lastname}`;
          },
          sortable: true,
          thClass: 'pl-7',
          tdClass: 'pl-7'
        },
        {
          key: 'member.email',
          label: this.$t('MEMBER.EMAIL'),
          sortable: true
        },
        {
          key: 'member.phone',
          label: this.$t('MEMBER.PHONE'),
          sortable: true
        },
        {
          key: 'do_manual',
          label: this.$t('SAMPLE.DO_MANUAL')
        },
        {
          key: 'status',
          label: this.$t('SAMPLE.STATUS')
        }
      ],
      list: []
    };
  }
};
</script>
