<template>
  <b-modal ref="confirmModal" hide-footer :title="title">
    <b-row>
      <b-col lg="12">
        <div class="d-block text-center">
          <p v-html="message" :class="variant"></p>
          <p v-if="message2" v-html="message2" ></p>
        </div>
      </b-col>
    </b-row>
    <div v-if="confirm_with_text">
      <b-row>
        <b-col>
          <p>{{ $t('COMMON.WRITE_CONFIRM_TEXT_INFO') }} <strong>{{ confirm_with_text }}</strong></p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          
          <memlist-text-input
              id="confirm_text_box"
              name="confirm_text_box"
              :title="$t('COMMON.WRITE_CONFIRM_TEXT')"
              layout="vertical"
              :validation_function="validate_confirm_text"
              validation_type="TEXT"

              v-model="input_text"

              :placeholder="$t('COMMON.WRITE_CONFIRM_TEXT')"
              :required="true"
              
              @validated="input_validated"
              @update:value="input_updated"
              >
            </memlist-text-input>

        </b-col>
      </b-row>
    </div>
    
    <b-row class="mt-8 mb-8">
      <b-col lg="6">
        <b-button :disabled="is_disabled_confirm" class="" :variant="confirmColor" block @click="onConfirm">{{confirm ? confirm : $t('COMMON.CONFIRM') }}</b-button>
      </b-col>
      <b-col lg="6">
        <b-button class="" variant="outline-secondary" block @click="onCancel"
          >{{cancel ? cancel : $t('COMMON.CANCEL') }}</b-button
        >
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: 'confirm-modal',
  data() {
    return {
      input_text: null,
      valid: false,
    };
  },
  props: {
    type: {
      type: String,
      default: 'danger'
    },
    variant: {
      type: String,  // Expecting a string that contains the class name
      default: '' // Mark it as required, or provide a default if it's optional
    },
    title: String,
    message: String,
    message2: { type: String, default: null },
    confirm: { type: String, default: null },
    cancel: { type: String, default: null },
    confirm_with_text: { type: String, default: null },
  },
  emits: ['confirm', 'cancel'],
  async mounted() {},
  methods: {
    validate_confirm_text(val) {
      if (val === this.confirm_with_text) {
        this.valid = true;
      }
      else {
        this.valid = false;
      }

      return this.valid;
    },
    input_validated(field, value, valid) {},
    input_updated(field, newtext) {
      this.input_text = newtext;
    },
    show() {
      this.$refs['confirmModal'].show();
    },
    onConfirm() {
      this.$refs['confirmModal'].hide();

      this.$emit('confirm');
    },
    onCancel() {
      this.$refs['confirmModal'].hide();

      this.$emit('cancel');
    },
  },
  computed: {
    is_disabled_confirm() {
      if (this.confirm_with_text && !this.valid) {
        return true;
      }

      return false;
    },
    confirmColor(){
      switch (this.type) {
        case 'danger':
          return 'outline-danger'
        case 'success':
          return 'outline-success'
        case 'info':
          return 'outline-info'
        case 'warning':
          return 'outline-warning'
        case 'primary':
          return 'outline-primary'
      }
    }
  }
};
</script>
<style lang="css" scoped>
.text-danger {
  font-weight: bold;
}
</style>