<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">&nbsp;</span>
      </h3>
      <div class="card-toolbar">
        <div id="show-earlier" class="d-flex align-items-center mr-12">
          <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input
              type="checkbox"
              name=""
              v-model="show_history"
              @click="show_history = !show_history"
            />
            <span></span>
          </label>
          <span class="ml-3 cursor-pointer">{{ $t('SAMPLE.SHOW_HISTORY') }}</span>
        </div>

        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm"
          @click.prevent="createStickprovClicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('SAMPLE.CREATE') }}</a
        >
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <!--begin::Table-->

    <div class="card-body pt-0">
      <b-table
        id="stickprov-table"
        :fields="headers"
        :items="items"
        head-variant="light"
        class="w-100 mh-100"
        sticky-header
      >
        <template #cell(stickprov_id)="row">
          <div class="justify-content-end d-flex">
            <div v-if="row.item.is_stopped || !row.item.has_started">
              <a
                class="btn btn-icon btn-light btn-sm mx-3"
                @click.prevent="startEvent(row.item.stickprov_id, true)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <i class="fas fa-play" style="color:#3699FF"></i>
                  <!--end::Svg Icon-->
                </span>
              </a>
            </div>
            <div v-else>
              <a
                class="btn btn-icon btn-light btn-sm mx-3"
                @click.prevent="startEvent(row.item.stickprov_id, false)"
              >
                <div class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <i class="fas fa-stop" style="color:#e63113"></i>
                  <!--end::Svg Icon-->
                </div>
              </a>
            </div>
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm mr-3"
              @click.prevent="selectStickprovClicked(row.item.stickprov_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm"
              @click.prevent="deleteStickprovClicked(row.item.stickprov_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>
          </div>
        </template>
      </b-table>

    </div>

    <div class="card-body pt-0 pb-3">

    </div>

  </div>
  <!--end::Advance Table Widget 9-->
</template>
<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";
</style>

<script>
import axios from 'axios';
import StickprovTableRow from '@/view/pages/ml/stickprov/StickprovTableRow.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'stickprovs-table',
  props: ['items', 'currentCompanyId'],
  emits: ['showHistoryToggled', 'createStickprovClicked', 'selectStickprovClicked', 'deleteStickprovClicked', 'startEvent'],
  components: {
    StickprovTableRow
  },
  mixins: [ toasts ],
  watch: {
    show_history(newcheck, oldcheck) {
      this.$emit('showHistoryToggled', newcheck);
    }
  },
  methods: {
    createStickprovClicked() {
      this.$emit('createStickprovClicked');
    },
    selectStickprovClicked(stickprov_id) {
      this.$emit('selectStickprovClicked', stickprov_id);
    },
    deleteStickprovClicked(stickprov_id) {
      this.$emit('deleteStickprovClicked', stickprov_id);
    },
    startEvent(stickprov_id, status) {
      this.$emit('startEvent', stickprov_id, status);
    },

  },
  data() {
    return {
      show_history: false,
      headers: [
        {
          key: 'company.name',
          label: this.$t('COMPANY.COMPANY'),
          sortable: true,
          thClass: 'pl-7',
          tdClass: 'pl-7'
        },
        {
          key: 'deadline',
          label: this.$t('SAMPLE.DEADLINE'),
          thClass: 'w-110px',
          tdClass: 'w-110px',
          sortable: true
        },
        {
          key: 'num_members',
          label: this.$t('SAMPLE.SHORT_NUM_MEMBERS'),
          thClass: 'w-120px',
          sortable: true
        },
        {
          key: 'num_answers',
          label: this.$t('SAMPLE.SHORT_NUM_ANSWERS'),
          thClass: 'w-50px'
        },
        {
          key: 'num_confirm',
          label: '# ' + this.$t('COMMON.YES'),
          thClass: 'w-50px'
        },
        {
          key: 'num_reject',
          label: '# ' + this.$t('COMMON.NO'),
          thClass: 'w-45px'
        },
        {
          key: 'stickprov_id',
          label: '',
          thClass: 'w-140px pl-0',
          tdClass: 'w-140px pl-0'
        }
      ],
      list: []
    };
  }
};
</script>
<style scoped>
  @media screen and (max-width: 768px) {
    #show-earlier {
      margin-bottom: 1rem;
    }
  }
</style>
